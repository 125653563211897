<div class="container-fluid">
  <div class="row">
    <div
      class="col-xl-5 d-none d-xl-flex flex-column justify-content-between gap-3 vh-100 position-relative p-0 bg-white"
    >
      <div
        class="position-absolute h-100 w-100 opacity-25 bg-black for-dark"
      ></div>
      <div class="text-center mt-5">
        <h2 class="text-primary lh-base fs-1 fw-bolder">
          Translation Management
        </h2>
      </div>
      <div
        class="h-100"
        [ngStyle]="{
          'background-image': 'url(assets/images/login/signin.jpg)',
          'background-size': 'cover',
          'background-position': 'center',
          display: 'block'
        }"
      >
        <img
          class="bg-img-cover bg-center d-none"
          src="assets/images/login/signin.jpg"
          alt="looginpage"
        />
      </div>
    </div>
    <div class="col-xl-7 p-0">
      <div
        class="bg-cover bg-center"
        style="background-image: url('assets/images/login/login_bg.jpg')"
      >
        <div class="login-card login-dark bg-cover">
          <div>
            <div class="d-flex justify-content-center">
              <a
                class="logo p-4 position-relative rounded-circle"
                routerLink="/"
                style="width: max-content"
              >
                <div
                  class="position-absolute top-0 start-0 w-100 h-100 rounded-circle"
                  style="
                    background: radial-gradient(#fff, transparent);
                    filter: blur(20px);
                  "
                ></div>
                <img
                  class="img-fluid position-relative"
                  style="width: 60px; z-index: 2"
                  src="assets/images/logo/my-logo.png"
                  alt="looginpage"
                />
              </a>
            </div>
            <div class="login-main">
              <form
                (submit)="login()"
                [formGroup]="loginForm"
                class="theme-form"
              >
                <h4>Sign in to account</h4>
                <p>Enter your email & password to login</p>
                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input
                    formControlName="email"
                    class="form-control"
                    type="email"
                    required=""
                    placeholder="app@yourmail.com"
                  />
                  <div
                    *ngIf="email.touched && email.errors?.required"
                    class="text text-danger mt-1"
                  >
                    Email is required
                  </div>
                  <div
                    *ngIf="email.touched && email.errors?.email"
                    class="text text-danger mt-1"
                  >
                    Invalid Email
                  </div>
                </div>
                <div class="form-group">
                  <div class="position-relative">
                    <label class="col-form-label">Password</label>
                    <input
                      formControlName="password"
                      class="form-control"
                      [type]="show ? 'text' : 'password'"
                      name="login[password]"
                      required=""
                      placeholder="*********"
                    />
                    <div
                      class="show-hide"
                      (click)="showPassword()"
                      *ngIf="!show"
                    >
                      <span class="show"></span>
                    </div>
                    <div
                      class="show-hide"
                      (click)="showPassword()"
                      *ngIf="show"
                    >
                      <span class="Hide"></span>
                    </div>
                  </div>
                  <div
                    *ngIf="password.touched && password.errors?.required"
                    class="text text-danger mt-1"
                  >
                    Password is required
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox" />
                    <label class="" for="checkbox1">Remember password</label>
                  </div>
                  <a
                    [routerLink]="'/authentication/forgot-password'"
                    class="link"
                    >Forgot password?</a
                  >
                  <button
                    class="btn btn-primary d-block w-100 mt-4"
                    type="submit"
                    [disabled]="loginForm.invalid"
                  >
                    Sign in
                  </button>
                </div>
                <p class="mt-3 mb-0">
                  Don't have account?
                  <a
                    [routerLink]="'/authentication/register/simple'"
                    class="ms-2"
                    >Create Account</a
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
