<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img
            class="img-fluid for-light"
            src="assets/images/logo/logo.png"
            alt=""
          />
          <img
            class="img-fluid for-dark"
            src="assets/images/logo/logo_dark.png"
            alt=""
          />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons
          [icon]="'align-center'"
          class="status_toggle middle"
        ></app-feather-icons>
      </div>
    </div>
    <div
      class="left-header col-xl-9 col-lg-7 col-md-5 col-4 p-0 d-flex align-items-center gap-2"
    >
      <h1 class="fs-4 m-0 white-space-nowrap">{{ title }} |</h1>
      <div class="w-50 overflow-hidden" style="text-overflow: ellipsis">
        <span class="white-space-nowrap"
          >Handle user management and role assignments within the Language
          Microservice.</span
        >
      </div>
      <!-- <app-swiper></app-swiper> -->
    </div>
    <div class="nav-right col-auto pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">
        <li>
          <span class="header-search" (click)="searchToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#search"></use>
            </svg>
          </span>
        </li>
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
